body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active {
  background-color: gray;
}

a, a:focus, a:hover {
  font-weight: 500;
  color: #9c27b0;
  text-decoration: none;
  cursor: pointer;
}

.Mui-selected {
  opacity: 0.6 !important;
  background-color: rgba(6, 0, 0, 0.0) !important;
  pointer-events: none;
  cursor: not-allowed;
}

.MuiCardMedia-root {
  background-position: initial !important;
}

/* tr:hover {
    background-color: aliceblue !important;
} */

.MuiTableRow-root:hover {
    background-color: aliceblue;
}
